import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {TopBar} from '../components/shared/top-bar';
import {ModuleInfo} from '../models/module-info';
import {FormOverlay} from '../components/shared/form-overlay';
import {FeedbackForm} from '../components/shared/feedback-form';
import {FooterIconBar} from '../components/shared/footer-icon-bar';
import {FooterLinkContainer} from '../components/shared/footer-link-container';
import {Footer} from '../components/shared/footer';
import {MarkdownRemark} from '../models/markdown-remark';
import {graphql} from 'gatsby';
import {UsecaseModel} from '../models/usecase';
import {Feature} from '../models/feature';
import {ContactFormConfig} from '../models/contact-form-config';
import {Metatags} from './metatags';
import {Favicon} from './favicon';
import {production} from 'gatsby-env-variables';

export interface BasePageData {
  modules: MarkdownRemark<ModuleInfo>
  hubspot: MarkdownRemark<{
    hubSpotPortalId: number;
    hubSpotFormId: string;
  }>;
  contactFormConfig: MarkdownRemark<ContactFormConfig>;
  usecases: MarkdownRemark<UsecaseModel>;
  integrations: MarkdownRemark<{
    title: string;
    logo: string;
    position: number;
    backgroundColor: string;
    description: string;
    url: string;
  }>;
  logos: MarkdownRemark<{
    logoHeaderLight: string;
    logoHeaderDark: string;
    logoShort: string;
    logoFooter: string;
  }>;
  metaInfo: MarkdownRemark<{
    siteTitle: string;
    siteDescription: string;
    siteDescriptionTitle: string;
    siteImage: string;
    siteUrl: string;
  }>;
  navigation: MarkdownRemark<{
    topCenterNavigation: Array<{
      text: string;
      templateName?: 'moduleListTemplate' | 'regularPopUpTemplate';
      url?: string;
      links?: Array<{
        logo: string;
        altText: string;
        link: string;
        title: string;
        description: string;
      }>;
      useCasesList?: Array<{
        useCase: string;
        position: number;
      }>;
    }>;
    footerIconBar: Array<{
      footerIcon: string;
      footerIconText: string;
    }>;
    footerNavItemsTitle: string,
    footerNavItems: Array<{
      footerNavItemName: string;
      Position: number;
      url: string;
    }>;
    footerIntegrationsTitle: string;
    footerIntegrationItems: Array<{
      integrationName: string;
      Position: number;
    }>;
    footerBlogItemsTitle: string;
    footerFeatureItemsTitle: string;
    footerFeatureItems: string[];
    socialLinks: Array<{
      navItemName: string;
      altText: string;
      url: string;
    }>;
    copyRightItems: Array<{
      itemName: string;
      url: string;
    }>;
  }>;
  features: MarkdownRemark<Feature>;
  blogArticles: MarkdownRemark<{
    title: string;
    urlFriendlyName: string;
    category: string;
    createdDate: string;
    updatedDate: string;
  }>
  blogCategories: MarkdownRemark<{
    name: string;
    urlFriendlyName: string;
  }>
  contact: MarkdownRemark<{
    title: string;
    subText: string;
    inputPlaceholder: string;
    buttonText: string;
  }>;
}

export const BasePageHeader = (props: {
  logoLight: string;
  logoDark: string;
  logoShort: string;
  hubspotPortalId: number;
  hubspotFormId: string;
  contactFormConfig: ContactFormConfig;
  siteTitle: string;
  siteDescription: string;
  siteDescriptionTitle: string;
  siteImage: string;
  siteUrl: string;
  links: Array<{
    text: string;
    templateName?: 'moduleListTemplate' | 'regularPopUpTemplate';
    url?: string;
    links?: Array<{
      logo: string;
      altText: string;
      link: string;
      title: string;
      description: string;
    }>;
    useCasesList?: Array<{
      useCase: string;
      position: number;
    }>;
  }>;
  usecases: Array<UsecaseModel>;
  modules: Array<ModuleInfo>,
  isFormVisible?: boolean;
  email?: string;
  onFormClose?: () => void
  theme?: 'light' | 'dark',
  contentType?: 'site-page' | 'blog-post'
}): JSX.Element => {

  const [isFormOpen, setFormVisibility] = useState(false);

  useEffect(() => {
    setFormVisibility(props.isFormVisible ?? false);
  }, [props.isFormVisible]);

  useEffect(() => {
    const handleAnchors = () => {
      if (document.readyState === 'complete') {
        const target = window.location.hash;
        if (target && document.querySelector(target)) {
          setTimeout(() => {
            const top = (document.querySelector(target) as HTMLElement)?.offsetTop ?? 70;
            window.scrollTo(0, top - 70);
          }, 50);
        }
      }
    };

    if (document.readyState === 'complete') {
      handleAnchors();
    } else {
      document.addEventListener('readystatechange', handleAnchors);
    }

    return () => {
      document.removeEventListener('readystatechange', handleAnchors);
    };
  });

  const onFormClose = () => {
    setFormVisibility(false);
    props?.onFormClose?.();
  };

  useEffect(() => {
    (window._hsq ??= []).push(['setContentType', props.contentType ?? 'site-page']);
  });

  useEffect(() => {

    const handler = () => {
      if (window.pageYOffset > 0) {
        document.body.classList.add('sticky');
      } else {
        document.body.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handler);

    handler();

    return () => window.removeEventListener('scroll', handler);
  });

  return (
    <React.Fragment>
      <Helmet>
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js"
                data-cbid="f73c173f-9e8a-46e3-a6f8-a6d1d8730357" data-blockingmode="auto" type="text/javascript"/>
        <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/4314722.js"/>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-B7QBDD3B7F"></script>
        <meta name="theme-color" content="#157ee5"/>
      </Helmet>
      <Helmet>
        <script type="text/javascript">{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-B7QBDD3B7F', {'debug_mode': ${!production}});
        `}</script>
        {!production && <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/19895967.js"></script>}
      </Helmet>
      <Metatags
        siteTitle={props.siteTitle}
        descriptionTitle={props.siteDescriptionTitle}
        description={props.siteDescription}
        image={props.siteImage}
        url={props.siteUrl}
      />
      <Favicon/>

      <link itemProp="thumbnailUrl" href={props.siteImage} />
      <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
        <link itemProp="url" href={props.siteImage} />
      </span>

      <TopBar
        logoLight={props.logoLight}
        logoDark={props.logoDark}
        logoShort={props.logoShort}
        links={props.links}
        onFormOpen={() => setFormVisibility(true)}
        modules={props.modules}
        usecases={props.usecases}
        theme={props.theme ?? 'light'}
      />
      <FormOverlay
        isOpen={isFormOpen}
        email={props.email}
        contactFormConfig={props.contactFormConfig}
        hubSpotFormId={props.hubspotFormId}
        hubSpotPortalId={props.hubspotPortalId}
        onClose={() => onFormClose()}
        modules={props.modules}
      />
    </React.Fragment>
  );
};

export const BasePageFooter = (props: {
  feedbackTitle: string;
  feedbackLabel: string;
  feedbackButtonText: string;
  feedbackInputPlaceholder: string;
  footerLogo: string;
  navigation: BasePageData['navigation'];
  integrations: BasePageData['integrations'];
  blogArticles: BasePageData['blogArticles'];
  blogCategories: BasePageData['blogCategories'];
  features: Array<Feature>;
}): JSX.Element => {
  const navItems = props.navigation.edges[0].node.frontmatter.footerNavItems
    .sort((a, b) => a.Position - b.Position)
    .map(navItem => ({
      url: navItem.url,
      label: navItem.footerNavItemName
    }));
  const integrationItems = props.navigation.edges[0].node.frontmatter.footerIntegrationItems
    .sort((a, b) => a.Position - b.Position)
    .map(navItem => ({
      url: props.integrations.edges.map(i => i.node.frontmatter).find(i => i.title === navItem.integrationName)?.url ?? '',
      label: navItem.integrationName
    }));
  const featureItems = props.navigation.edges[0].node.frontmatter.footerFeatureItems
    .map(featureName => props.features.find(f => f.title === featureName))
    .filter(f => f !== undefined)
    .map(f => ({
      url: '/features#' + (f as Feature).moduleName,
      label: (f as Feature).title
    }));
  const blogArticles = props.blogArticles.edges
    .sort((a, b) => {
      return (new Date(b.node.frontmatter.createdDate ?? 0)).getTime() - (new Date(a.node.frontmatter.createdDate ?? 0)).getTime();
    })
    .slice(0, 6)
    .map(i => i.node.frontmatter)
    .map(item => {
      const category = props.blogCategories.edges
        .map(i => i.node.frontmatter)
        .find(category => category.name === item.category);
      return {
        url: `/blog/${category?.urlFriendlyName}/${item.urlFriendlyName}`,
        label: item.title
      };
    });

  const iconBarItems = props.navigation.edges[0].node.frontmatter.footerIconBar
    .map(item => ({
      label: item.footerIconText,
      icon: item.footerIcon
    }));
  const socialLinks = props.navigation.edges[0].node.frontmatter.socialLinks
    .map(item => ({
      logo: item.navItemName,
      url: item.url,
      alt: item.altText
    }));
  const copyrightLinks = props.navigation.edges[0].node.frontmatter.copyRightItems
    .map(item => ({
      label: item.itemName,
      url: item.url
    }));

  return (
    <React.Fragment>
      <FeedbackForm
        title={props.feedbackTitle}
        label={props.feedbackLabel}
        buttonText={props.feedbackButtonText}
        inputPlaceholder={props.feedbackInputPlaceholder}
      />
      <FooterIconBar items={iconBarItems}/>
      <FooterLinkContainer blocks={[
        {title: props.navigation.edges[0].node.frontmatter.footerNavItemsTitle, links: navItems},
        {title: props.navigation.edges[0].node.frontmatter.footerFeatureItemsTitle, links: featureItems},
        {title: props.navigation.edges[0].node.frontmatter.footerIntegrationsTitle, links: integrationItems},
        {title: props.navigation.edges[0].node.frontmatter.footerBlogItemsTitle, links: blogArticles},
      ]}/>
      <Footer
        logo={props.footerLogo}
        socialLinks={socialLinks}
        copyrightLinks={copyrightLinks}
      />
    </React.Fragment>
  );
};

export const basePageQuery = graphql`
  fragment BaseData on Query {
    modules: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/modules//"
      }
    }
    sort: { fields: [frontmatter___position], order: ASC }) {
      edges {
        node {
          frontmatter {
            name
            position
            icon
            description
            color
            altText
          }
        }
      }
    }
    usecases: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/^(?!.*\/site\/).*usecases\/"
      }
    }
    sort: { fields: [frontmatter___position], order: ASC }) {
      edges {
        node {
          frontmatter {
            useCaseImage
            description
            title
            position
            backgroundImage
            link
          }
        }
      }
    }
    blogArticles: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*blogs/"}
      },
    ) {
      edges {
        node {
          frontmatter {
            title
            urlFriendlyName
            category
            createdDate
            updatedDate
          }
        }
      }
    }
    blogCategories: allMarkdownRemark(filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*categories/"}
      },
    ) {
      edges {
        node {
          frontmatter {
            name
            urlFriendlyName
          }
        }
      }
    }
    features: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/^(?!.*\/site\/).*features\/"
      }
    }) {
      edges {
        node {
          frontmatter {
            img
            altText
            title
            text
            moduleName
          }
        }
      }
    }
    hubspot: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/base-settings.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            hubSpotPortalId
            hubSpotFormId
          }
        }
      }
    }
    contactFormConfig: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/base-settings.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            contactFormSettings {
              submissionText
              checkBoxList {
                label
                checkBoxDefaultChecked
                hubSpotSubscriptionId
              }
            }
          }
        }
      }
    }
    integrations: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/^(?!.*\/site\/).*integrations\/"
      }
    }) {
      edges {
        node {
          frontmatter {
            title
            position
            logo
            description
            backgroundColor
            url
          }
        }
      }
    }
    navigation: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/base-settings.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            topCenterNavigation {
              text
              templateName
              url
              links {
                logo
                altText
                link
                title
                description
              }
              useCasesList {
                useCase
                position
              }
            }
            footerIconBar {
              footerIcon
              footerIconText
            }
            footerNavItemsTitle
            footerNavItems {
              footerNavItemName
              Position
              url
            }
            footerFeatureItemsTitle
            footerFeatureItems
            footerIntegrationsTitle
            footerBlogItemsTitle
            footerIntegrationItems {
              integrationName
              Position
            }
            socialLinks {
              navItemName
              altText
              url
            }
            copyRightItems {
              itemName
              url
            }
          }
        }
      }
    }
    logos: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/base-settings.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            logoHeaderLight
            logoHeaderDark
            logoShort
            logoFooter
          }
        }
      }
    }
    metaInfo: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/base-settings.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            siteTitle
            siteDescription
            siteDescriptionTitle
            siteImage
            siteUrl
          }
        }
      }
    }
    contact: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/contactsection.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            title
            subText
            inputPlaceholder
            buttonText
          }
        }
      }
    }
  }
`;
