import React, {CSSProperties} from 'react';
import {FooterLinkList} from '../../models/footer-link-list';
import {Link} from 'gatsby';
import styled, {css} from 'styled-components';

const headerStyle: CSSProperties = {
  marginBottom: 11,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 2.29,
  color: '#4231a9',
  textTransform: 'uppercase'
};

const LinkStyle = css`
  font-size: 16px;
  color: #54595a;
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
  
  &:hover {
    color: #0a0a0a;
  }
`;

const StyledGatsbyLink = styled(props => <Link {...props} />)`${LinkStyle}`;
const StyleGenericLink = styled.a`${LinkStyle}`;

export const FooterLinks = (props: FooterLinkList): JSX.Element => {
  return (
    <div>
      <span style={headerStyle}>{props.title}</span>
      <br/>
      {
        props.links.map((item, index) => {
          const internal = /^\/(?!\/)/.test(item.url); // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
          const hrefAttribute = item.url
            ? { href: item.url }
            : {};
          return (
            <React.Fragment key={index}>
              {
                internal
                ? <StyledGatsbyLink to={item.url}>{item.label}</StyledGatsbyLink>
                : <StyleGenericLink rel="noreferrer" target="_blank" {...hrefAttribute}>{item.label}</StyleGenericLink>
              }
              <br/>
            </React.Fragment>
          );
        })
      }
    </div>
  );
};
