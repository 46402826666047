import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{color?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  --bar-bg: ${props => props.color};

  .menu-icon {
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;

    .menu-icon__cheeckbox {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 2;
      -webkit-touch-callout: none;
      position: absolute;
      opacity: 0;
      margin: 0;
    }

    div {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 22px;
      height: 12px;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--bar-bg, #000);
      border-radius: 1px;
      transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

      &:first-of-type {
        top: 0;
      }

      &:last-of-type {
        bottom: 0;
      }
    }

    &.active,
    .menu-icon__cheeckbox:checked + div {
      span {
        &:first-of-type {
          transform: rotate(45deg);
          top: 5px;
        }

        &:last-of-type {
          transform: rotate(-45deg);
          bottom: 5px;
        }
      }
    }

    &.active:hover span:first-of-type,
    &.active:hover span:last-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
      width: 22px;
    }
  }
`;


export const HamburgerMenu = (props: {
  onClick?: () => void;
  color?: string;
}): JSX.Element => {
  return (
    <Container color={props.color}>
      <div className="menu-icon">
        <input className="menu-icon__cheeckbox" type="checkbox" onChange={() => props.onClick?.()}/>
        <div>
          <span/>
          <span/>
        </div>
      </div>
    </Container>
  );
};
