import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const titleStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 600,
  color: 'black',
  display: 'block',
  marginBottom: 7,
};

const descriptionStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '21px',
  color: '#788296',
};

const ModuleItem = styled.div<{color: string}>`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;

  &:hover {
    background: ${props => props.color};
  }
`;

const ModuleList = styled.div<{length: number}>`
  display: grid;
  grid-template-columns: repeat(${props => Math.min(3, props.length)}, 247px);
  grid-row-gap: 9px;
  grid-column-gap: 26px;
  padding: 0;
  flex: 1;
`;

export const TopBarPopupList = (props: {
  items: Array<{
    icon: string;
    altText: string;
    title: string;
    link: string;
    text: string;
    color: string;
  }>
}): JSX.Element => {
  return (
    <ModuleList length={props.items.length}>
      {props.items.map((item, index) => (
        <a href={item.link} key={index} style={{textDecoration: 'none'}}>
          <ModuleItem color={item.color}>
            <img style={{marginTop: 2, marginRight: 8}} width={32} src={item.icon} alt={item.altText}/>
            <div>
              <div style={titleStyle}>{item.title}</div>
              <div style={descriptionStyle}>
                <ReactMarkdown>{item.text}</ReactMarkdown>
              </div>
            </div>
          </ModuleItem>
        </a>
      ))}
    </ModuleList>
  );
};
