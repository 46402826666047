import React, {useEffect, useState} from 'react';
import {Form} from './form';
import {ModuleInfo} from '../../models/module-info';
import styled from 'styled-components';
import { ContactFormConfig } from '../../models/contact-form-config';
import {device} from '../../models/devices';

const FormContainer = styled.div`
  position: absolute;
  left: 103px;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100vh;
  overflow: auto;
  
  @media screen and ${device.tablet} {
    left: 0;
    top: 0;
    transform: none;
  }
`;

const LeftSideLabel = styled.div`
  font-size: 18px;
  color: white;
  background-image: linear-gradient(to top, #76d3ff, #157ee5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  writing-mode: vertical-lr;
  transform: scale(-1);
  text-align: center;
  line-height: 60px;
  letter-spacing: 1px;
`;

const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #45a8f2;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 50;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  
  ${FormContainer}{
    transform: translateX(-300px) translateY(-50%);
    opacity: 0;
    transition: transform 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.3s;

    @media screen and ${device.tablet} {
      transform: translateX(-300px);
    }
  }
  
  ${Triangle} {
    transform: translateX(-300px) translateY(-50%);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  ${LeftSideLabel} {
    transform: translateX(-300px) scale(-1);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  &.open {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    
    ${FormContainer}, ${LeftSideLabel}, ${Triangle} {
      transform: translateY(-50%);
      opacity: 1;
      
      @media screen and ${device.tablet} {
        transform: none;
        top: 0;
      }
    }
    
    ${LeftSideLabel} {
      transform: scale(-1);
      opacity: 1;
    }
  }
`;

export const FormOverlay = (props: {
  isOpen: boolean;
  modules: Array<ModuleInfo>;
  email?: string;
  contactFormConfig: ContactFormConfig;
  hubSpotFormId: string;
  hubSpotPortalId: number;
  onClose?: () => void;
}): JSX.Element => {

  const [isOpen, setOpenFlag] = useState(props.isOpen);

  useEffect(() => {
    setOpenFlag(props.isOpen);
  }, [props.isOpen]);

  const closeForm = () => {
    setOpenFlag(false);
    props.onClose?.();
  };

  return (
    <Container className={isOpen ? 'open' : ''}>
      <LeftSideLabel className={'desktop-only'}>Join us today!</LeftSideLabel>
      <Triangle className={'desktop-only'}/>
      <FormContainer>
        <Form contactFormConfig={props.contactFormConfig} email={props.email} modules={props.modules} hubSpotFormId={props.hubSpotFormId} hubSpotPortalId={props.hubSpotPortalId} onClose={closeForm}/>
      </FormContainer>
    </Container>
  );
};
