import React, {CSSProperties} from 'react';
import {FooterData} from '../../models/footer-data';
import SwissLogo from '../../images/swiss-made.png';
import styled from 'styled-components';
import {device} from '../../models/devices';

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 31px;
  padding-bottom: 30px;
  display: grid;
  grid-template-rows: 26px 63px 20px;
  text-align: right;
  grid-template-areas:
    "logo space social"
    "logo space copyright"
    "logo space swiss";

  @media screen and ${device.tablet} {
    padding: 24px;
    grid-template-columns: 1fr auto;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    text-align: left;
    grid-template-areas:
      "logo social"
      "copyright copyright"
      "swiss swiss"
  ;
  }
`;

const linkStyle: CSSProperties = {
  color: '#7484a9',
  fontWeight: 500,
  fontSize: 14,
  textDecoration: 'none',
  gridArea: 'copyright',
  // marginLeft: 'auto',
  alignSelf: 'center'
};

const Link = styled.a`
  color: #7484a9;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  
  &:hover {
    color: #0a0a0a;
    
    img {
      filter: brightness(0.2);
    }
  }
`;

const Logo = styled.img`
  grid-area: logo;
  @media screen and ${device.tablet} {
    width: 77px;
    margin-bottom: 9px;
  }
`;

export const Footer = (props: FooterData): JSX.Element => {
  const year = (new Date()).getFullYear();
  return (
    <footer>
      <Wrapper>
        <Logo src={props.logo} alt="Logo"/>
        <div style={{gridArea: 'social', marginLeft: 'auto', alignSelf: 'center'}}>
          {
            props.socialLinks.map((item, index) => (
              <Link href={item.url} key={index}>
                <img style={{height: 20, marginLeft: 20}} src={item.logo} alt={item.alt}/>
              </Link>
            ))
          }
        </div>
        <div style={linkStyle}>
          © {year} LimeFlight
          {
            props.copyrightLinks.map((item, index) => (
              <React.Fragment key={index}>
                <span> | </span>
                <Link href={item.url}>{item.label}</Link>
              </React.Fragment>
            ))
          }
        </div>
        <a href="https://www.swissmadesoftware.org/en/home/home.html" style={{gridArea: 'swiss'}} target="_blank" rel="noreferrer">
          <img style={{height: 30, gridArea: 'swiss', marginLeft: 'auto'}} src={SwissLogo} alt="swiss made software"/>
        </a>
      </Wrapper>
    </footer>
  );
};
