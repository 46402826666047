import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import {device} from '../../models/devices';

const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-left: 16px;
  display: inline-block;
  
  p {
    margin: 0;
    display: inline;
  }
  
  a {
    color: inherit;
  }
  
  @media screen and ${device.tablet} {
    margin-left: 21px;
  }
`;

const containerStyle: CSSProperties = {
  borderTop: '1px solid #e6ecfb',
  borderBottom: '1px solid #e6ecfb',
};

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  max-width: 1170px;

  @media screen and ${device.tablet} {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding: 32px 24px 8px;

    > div {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
    }
  }
`;

export const FooterIconBar = (props: {items: Array<{icon: string; label: string;}>}): JSX.Element => {
  return (
    <section style={containerStyle}>
      <Wrapper>
        {props.items.map((item, index) => (
          <div key={index}>
            <img style={{verticalAlign: 'middle'}} src={item.icon} alt=""/>
            <Label>
              <ReactMarkdown>{item.label}</ReactMarkdown>
            </Label>
          </div>
        ))}
      </Wrapper>
    </section>
  );
};
