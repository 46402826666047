import { showModal } from './modal-controller';
import Cookies from 'js-cookie';
import { getGuid } from './guid';
import { hubspotEmailField, hubspotFirstNameField, hubspotLastNameField, hubspotLastCompanyField, hubspotModulesField } from '../components/shared/constants';
import { ModuleInfo } from '../models/module-info';
import { ContactFormConfig } from '../models/contact-form-config';


export interface HubSpotFormFields {
    name: string;
    value: FormDataEntryValue | null;
}
export interface HubSpotForm {
  fields: HubSpotFormFields[];
  legalConsentOptions: HubSpotLegalConsent;
}

export interface HubSpotLegalConsent {
    consent:{
      consentToProcess: boolean,
      text: string;
      // String; The text displayed to the visitor for the Consent to process checkbox
      communications: HubSpotSubcriptionListItem[];
    }
}
export interface HubSpotSubcriptionListItem {
  value: boolean;
  subscriptionTypeId: string;
  text: string;
}
    
export function setBasicHubspotFormFields(data: FormData, modules: Array<ModuleInfo>, contactFormConfig: ContactFormConfig): HubSpotForm {
  const moduleFields = modules.filter(moduleName => data.get(moduleName.name)).map(m =>{
    return {
      name: hubspotModulesField,
      value: data.get(m.name),
    };
  });
  const legalConsent: HubSpotLegalConsent = {
      consent: {
        consentToProcess: true,
        text: contactFormConfig.contactFormSettings.submissionText,
        communications: contactFormConfig.contactFormSettings.checkBoxList.filter(checkBoxItem => data.get(checkBoxItem.hubSpotSubscriptionId)).map(m =>{
          return {
            value: true,
            text: m.label,
            subscriptionTypeId: m.hubSpotSubscriptionId,
          };
        })
      }
  };
    return {
      legalConsentOptions: legalConsent,
      fields: [
        {
          name: hubspotEmailField,
          value: data.get('email'),
        },
        {
          name: hubspotFirstNameField,
          value: data.get('firstName'),
        },
        {
          name: hubspotLastNameField,
          value: data.get('lastName'),
        },
        {
          name: hubspotLastCompanyField,
          value: data.get('company'),
        },
        ...moduleFields
      ]
    };
}

export function hubspotFormSubmit(portalId: number, formId: string, form: HubSpotForm): void {
    const isBrowser = typeof window !== 'undefined';
      const hubspotutk = 'hubspotutk';
      const utkIsSet = Cookies.get(hubspotutk);
      if(!utkIsSet) {
        Cookies.set(hubspotutk, getGuid());
      }
      const hutk = isBrowser ? Cookies.get(hubspotutk) : null;
      const pageUri = isBrowser ? window.location.href : null;
      const pageName = isBrowser ? document.title : null;
      const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
      const body = {
        submittedAt: Date.now().toString(),
        ...form,
        context: {
          hutk,
          pageUri,
          pageName,
        },
      };
  
      // These specific headers are required for whatever reason,
      // so don't forget to include them. 
      
      fetch(postUrl, {
        method: 'post',
        body: JSON.stringify(body),
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        }),
      })
        .then(res => res.json())
        .then(() => {
          showModal();
          console.log('success');
        })
        .catch(err => {
          console.log('error');
        });
  }