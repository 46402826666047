import React from 'react';
import Curves from '../../images/form-curves.svg';
import {InputWithButton} from './input-with-button';
import styled from 'styled-components';
import {device} from '../../models/devices';
import ReactMarkdown from 'react-markdown';

const Container = styled.form`
  background-color: #fdf0f2;
  background-image: url(${Curves});
  max-width: 1170px;
  background-position: center;
  border-radius: 19px;
  padding: 51px 55px;
  box-sizing: border-box;
  display: flex;
  margin: 0 auto 111px;
  align-items: center;
  justify-content: space-between;

  @media screen and ${device.tablet} {
    padding: 24px;
    margin: 0 24px 80px;
    flex-direction: column;
    align-items: stretch;
  }

  @media screen and (max-width: 965px) {
    height: auto;
  }
`;

const Title = styled.h2`
  font-size: 32px;
  color: #4f40ab;
  font-weight: bold;
  margin: 0 0 9px;

  @media screen and ${device.tablet} {
    line-height: 1.4em;
    letter-spacing: 0.6px;
    margin-bottom: 12px;
  }
`;

const Label = styled.div`
  font-size: 18px;
  color: #7b85a0;
  
  @media screen and ${device.tablet} {
    margin-bottom: 30px;
  }
`;

export const FeedbackForm = (props: {
  title: string;
  label: string;
  inputPlaceholder: string;
  buttonText: string;
}): JSX.Element => {

  const openChat = (email: string): void => {
    (window._hsq ??= []).push(['identify', {email}]);
    window.HubSpotConversations.widget.open();
  };

  return (
    <Container onSubmit={e => {e.preventDefault(); openChat(((e.target as HTMLFormElement).elements.namedItem('email') as HTMLInputElement)?.value);}}>
      <div>
        <Title><ReactMarkdown>{props.title}</ReactMarkdown></Title>
        <Label><ReactMarkdown>{props.label}</ReactMarkdown></Label>
      </div>
      <InputWithButton
        inputPlaceholder={props.inputPlaceholder}
        buttonText={props.buttonText}
        buttonColor={'violet'}
        inputWidth={339}
      />
    </Container>
  );
};
