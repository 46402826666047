import React, { CSSProperties, FormEvent } from 'react';
import CrossIcon from '../../images/cross.svg';
import { ModuleInfo } from '../../models/module-info';
import styled from 'styled-components';
import {
  hubspotFormSubmit,
  setBasicHubspotFormFields,
} from '../../helpers/hs-form-submit';
import GdprConsent from './gdpr-consent';
import { ContactFormConfig } from '../../models/contact-form-config';
import { device } from '../../models/devices';

const Container = styled.form`
  padding: 21px 42px 40px;
  border-radius: 10px;
  background-color: white;
  width: 474px;
  border-top: 10px solid rgb(70, 165, 240);
  box-sizing: border-box;
  position: relative;

  @media screen and ${device.tablet} {
    width: 100vw;
    padding: 15px 28px 46px;
    border-radius: 0;
  }
`;

const headerStyle: CSSProperties = {
  fontSize: 32,
  fontWeight: 600,
  lineHeight: 1.31,
  color: 'black',
  boxShadow: 'inset 0 -0.175em white, inset 0 -0.6em #e6f5f9',
  display: 'inline',
};

const Input = styled.input`
  padding: 17px 16px;
  border-radius: 4px;
  border: solid 1px #e3e7eb;
  background-color: #fcfdfe;
  font-size: 14px;
  font-weight: 700;
  color: #54595a;
  min-width: 0;
  grid-column: 1 / span 2;

  @media screen and ${device.tablet} {
    padding: 15px 16px;
  }

  &.half {
    grid-column: initial;

    @media screen and ${device.tablet} {
      grid-column: 1 / span 2;
    }
  }
`;

const Textarea = styled.textarea`
  padding: 17px 16px;
  border-radius: 4px;
  border: solid 1px #e3e7eb;
  background-color: #fcfdfe;
  font-size: 14px;
  font-weight: 700;
  color: #54595a;
  min-width: 0;
  grid-column: 1 / span 2;
  resize: none;

  @media screen and ${device.tablet} {
    padding: 15px 16px;
  }
`;

const buttonStyle: CSSProperties = {
  borderRadius: 8,
  backgroundColor: '#4231a9',
  width: '100%',
  height: 48,
  border: 'none',
  fontSize: 14,
  fontWeight: 600,
  color: 'white',
  cursor: 'pointer',
};

const Grid = styled.section`
  margin: 29px 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 11px;

  @media screen and ${device.tablet} {
    grid-gap: 14px;
    margin-bottom: 30px;
  }
`;

const subHeaderStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  color: 'black',
};

const closeButtonStyle: CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  top: 16,
  right: 16,
};

// Copy/pasted code for module item

const ModuleItem = styled.label`
  &:nth-child(2n):last-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and ${device.tablet} {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  input {
    display: none;
    &:checked + .module-label {
      border-color: #4231a9;
      color: #444950;
      img {
        filter: initial;
      }
    }
  }
  .module-label {
    font-size: 14px;
    font-weight: 500;
    color: #7e868c;
    border-radius: 4px;
    border: solid 1px #e3e7eb;
    background-color: #fcfdfe;
    padding: 8px 16px;
    cursor: pointer;
    img {
      vertical-align: middle;
      margin-right: 8px;
      filter: grayscale(100%);
    }
  }
`;

export const Form = (props: {
  modules: Array<ModuleInfo>;
  email?: string;
  contactFormConfig: ContactFormConfig;
  hubSpotFormId: string;
  hubSpotPortalId: number;
  onClose?: () => void;
}): JSX.Element => {
  const isSSR = typeof window === 'undefined';

  const handleSubmit = (
    e: FormEvent,
    modules: Array<ModuleInfo>,
    portalId: number,
    formId: string,
    contactFormConfig: ContactFormConfig
  ) => {
    if (e) {
      e.preventDefault();
      const data = new FormData(e.target as HTMLFormElement);
      const form = setBasicHubspotFormFields(data, modules, contactFormConfig);

      form.fields = [
        ...form.fields,
        {
          name: 'message',
          value: data.get('message'),
        },
      ];
      hubspotFormSubmit(portalId, formId, form);

      props.onClose?.();
    }
  };

  return (
    <Container
      onSubmit={e =>
        handleSubmit(
          e,
          props.modules,
          props.hubSpotPortalId,
          props.hubSpotFormId,
          props.contactFormConfig
        )
      }
    >
      <header className={'desktop-only'} style={headerStyle}>
        Get Started with LimeFlight!
      </header>
      {!isSSR && (
        <div className={'mobile-only'} style={{ paddingRight: 20 }}>
          <header style={headerStyle}>Get Started with LimeFlight!</header>
        </div>
      )}
      <img
        onClick={props.onClose}
        style={closeButtonStyle}
        src={CrossIcon}
        alt="Close"
      />
      <Grid>
        <Input
          className={'half'}
          type="text"
          name="firstName"
          placeholder="First Name"
          required
        />
        <Input
          className={'half'}
          type="text"
          name="lastName"
          placeholder="Last Name"
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email Address"
          defaultValue={props.email}
          required
        />
        <Input type="text" name="company" placeholder="Company" required />
        <Textarea name="message" placeholder="Message" required />
      </Grid>
      <Grid style={{ border: 'none', padding: 0, marginBottom: 32 }}>
        <header style={{ ...subHeaderStyle, gridArea: '1 / 1 / 1 / 3' }}>
          Select modules you’re interested in:
        </header>
        {props.modules.map((module, index) => (
          <ModuleItem key={index}>
            <input
              name={module.name}
              type="checkbox"
              value={module.name}
              defaultChecked={true}
            />
            <div className={'module-label'}>
              <img src={module.icon} alt={module.altText} style={{width: 32, height: 32}} />
              {module.name}
            </div>
          </ModuleItem>
        ))}
      </Grid>
      <GdprConsent contactFormConfig={props.contactFormConfig} />
      <button style={buttonStyle} type="submit">
        Get Started
      </button>
    </Container>
  );
};
