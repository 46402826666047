import {FunctionComponent, createElement} from 'react';
import ReactDOM from 'react-dom';
import {ThanksModal} from '../components/shared/thanks-modal';

let container: Element;
if (typeof window !== 'undefined') {
  container = document.createElement('div');
  container.classList.add('overlay');
}

export function showModal(modalComponent: FunctionComponent = ThanksModal): void {
  document.body.classList.add('no-scroll');
  ReactDOM.render(
    createElement(modalComponent),
    document.body.appendChild(container)
  );

}

export function hideModal(): void {
  document.body.classList.remove('no-scroll');
  ReactDOM.unmountComponentAtNode(container);
  document.body.removeChild(container);
}
