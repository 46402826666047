import React from 'react';
import MailIcon from '../../images/mail.svg';
import styled from 'styled-components';
import {device} from '../../models/devices';
import { Button } from './button';

const Container = styled.div`
  @media screen and ${device.tablet} {
    button, input[type=submit] {
      width: 100%;
    }
  }
`;

const InputContainer = styled.div<{width: number | string}>`
  position: relative;
  margin-right: 8px;
  display: inline-block;
  width: ${props => typeof props.width === 'string' ? props.width : (props.width + 'px') };

  @media screen and ${device.tablet} {
    margin-bottom: 16px;
    width: 100%;
    margin-right: 0;
  }
`;

const Input = styled.input`
  height: 48px;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 700;
  background-color: white;
  padding-left: 56px;
  width: 100%;
  box-sizing: border-box;

  @media screen and ${device.tablet} {
    margin-right: 0;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InputWithButton = (props: {
  inputPlaceholder: string;
  buttonText: string;
  buttonColor: 'violet' | 'green' | 'blue';
  inputWidth: number | string;
}): JSX.Element => {
  return (
    <Container>
      <InputContainer width={props.inputWidth}>
        <Icon src={MailIcon} alt=""/>
        <Input
          type="email"
          style={{width: '100%'}}
          placeholder={props.inputPlaceholder}
          name={'email'}
        />
      </InputContainer>
      <Button
        type={'submit'}
        buttonStyle={'default'}
        color={props.buttonColor}
      >{props.buttonText}</Button>
    </Container>
  );
};
