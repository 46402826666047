import React, {CSSProperties} from 'react';
import {FooterLinkList} from '../../models/footer-link-list';
import {FooterLinks} from './footer-links';
import styled from 'styled-components';
import {device} from '../../models/devices';

const containerStyle: CSSProperties = {
  borderBottom: '1px solid #e6ecfb',
  paddingTop: 33,
};

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  > * {
    flex: 1;
    flex-basis: 1px;
  }

  @media screen and ${device.tablet} {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 24px 10px;
    > * {
      flex-basis: 50%;
      margin-bottom: 48px;
    }
  }
`;

export const FooterLinkContainer = (props: {blocks: FooterLinkList[]}): JSX.Element => {
  return (
    <section style={containerStyle}>
      <Wrapper>
        {props.blocks.map((list, index) => (
          <FooterLinks key={index} title={list.title} links={list.links}/>
        ))}
      </Wrapper>
    </section>
  );
};
