import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
  padding: 15px 54px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--primary-color);
  color: white;

  &.green { --primary-color: #31ead2; }
  &.blue { --primary-color: #0089ff; }
  &.violet { --primary-color: #4231a9; }
  
  &.default { background-color: var(--primary-color); }
  
  &.outline { color: var(--primary-color); background: white }
  
  &.green { color: #4f40ab !important; }
  
  &[disabled] { opacity: 0.5; cursor: not-allowed !important; }

  &:hover {
    cursor: pointer;
    box-shadow: 10px 12px 14px 0 rgba(0, 0, 0, 0.04);
    &.green {
      box-shadow: 5px 6px 28px 0 rgba(0, 0, 0, 0.09);
    }
  }
`;

export const Button = (props: {
  color: 'green' | 'blue' | 'violet';
  disabled?: boolean;
  buttonStyle: 'default' | 'outline';
  children?: React.ReactChild;
} & Record<string, unknown>): JSX.Element => {

  const {color, disabled, buttonStyle, children, ...childProps} = props;

  return (
    <ButtonElement
      className={`${props.color} ${props.buttonStyle}`}
      disabled={props.disabled}
      {...childProps}
    >
      {props.children}
    </ButtonElement>
  );
};
