import React from 'react';
import Background from '../../images/modal-background.svg';
import Icon from '../../images/thumbs-up.svg';
import Cross from '../../images/cross-white.svg';
import {hideModal} from '../../helpers/modal-controller';

export const ThanksModal = (): JSX.Element => {
  return (
    <div style={{
      width: 1170,
      height: 359,
      background: `url(${Background})`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderRadius: 10
    }}>
      <img style={{marginBottom: 9}} src={Icon} alt=""/>
      <header style={{
        color: 'white',
        fontWeight: 600,
        fontSize: 48,
        marginBottom: 16,
        lineHeight: '42px'
      }}>Thanks!</header>
      <div style={{
        color: 'white',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '1.75em'
      }}>We&apos;ll be right with you.</div>
      <img
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 16,
          right: 16
        }}
        src={Cross}
        alt="Close"
        onClick={() => hideModal()}
      />
    </div>
  );
};
