import React, {CSSProperties} from 'react';
import {ModuleInfo} from '../../models/module-info';
import Background from '../../images/mobile-nav-background.svg';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Arrows from '../../images/arrows-violet.svg';
import { Button } from './button';
import Chevron from '../../images/chevron-white.svg';
import ChevronDown from '../../images/chevron-down.svg';
import {UsecaseModel} from '../../models/usecase';
import {HSL} from '../../helpers/hsl';

const Link = styled.a`
  font-size: 32px;
  font-weight: 400;
  color: white;
  line-height: 48px;
  text-decoration: none;
  margin: 0 37px 0 24px;
  display: block;
  &.menu {
    background: url(${Chevron}) no-repeat center right;
  }
`;

const titleStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 600,
  color: 'black',
  display: 'block',
  marginBottom: 7,
};

const arrowStyle: CSSProperties = {
  background: `url(${Arrows})`,
  display: 'inline-block',
  width: 16,
  height: 12,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  verticalAlign: 'middle',
  marginLeft: 8
};

const descriptionStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '21px',
  color: '#788296',
};

const ModuleItem = styled.div<{color: string}>`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  background: ${props => props.color};
  margin-bottom: 12px;
  
  > img {
    width: 32px;
  }
`;

const UseCaseButton = styled.a`
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #157ee5;
  height: 45px;
  border-radius: 10px;
  box-shadow: 1px 1px 11px 0 rgba(216, 216, 216, 0.13);
  background-color: white;
  margin-bottom: 16px;
  line-height: 45px;

  &:hover {
    cursor: pointer;
    box-shadow: 10px 12px 14px 0 rgba(0, 0, 0, 0.04);
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const LinkContainer = styled.li`
  margin: 0 24px;
  padding: 26px 0 0;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s linear;

  ${MenuContainer} {
    height: 0;
    overflow: hidden;
    transition: height 0.3s linear;
  }
  
  &.expanded {
    background: white;
    padding-bottom: 30px;
    margin-bottom: 5px;
    ${Link}.menu {
      color: black;
      background-image: url(${ChevronDown});
    }
    
    ${MenuContainer} {
      margin-top: 21px;
    }
  }
`;

const MenuSection = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`;

export const MobileNavMenu = (props: {
  logo: string;
  links: Array<{
    text: string;
    templateName?: 'moduleListTemplate' | 'regularPopUpTemplate';
    url?: string;
    links?: Array<{
      logo: string;
      altText: string;
      link: string;
      title: string;
      description: string;
    }>;
    useCasesList?: Array<{
      useCase: string;
      position: number;
    }>;
  }>;
  usecases: Array<UsecaseModel>;
  onFormOpen?: () => void;
  modules: Array<ModuleInfo>;
}): JSX.Element => {

  const toggleMenu = (containerRef: React.RefObject<HTMLElement>, menuRef: React.RefObject<HTMLElement>): void => {
    const isExpanded = containerRef.current?.classList.contains('expanded');
    console.log(isExpanded);
    if (isExpanded) {
      if (menuRef.current) {
        menuRef.current.style.height = `${menuRef.current.scrollHeight}px`;
      }
      setTimeout(() => {
        containerRef.current?.classList.remove('expanded');
        if (menuRef.current) {
          menuRef.current.style.height = '0px';
        }
      });
    } else {
      containerRef.current?.classList.add('expanded');
      if (menuRef.current) {
        menuRef.current.style.height = `${menuRef.current.scrollHeight}px`;
      }
      setTimeout(() => {
        if (menuRef.current) {
          menuRef.current.style.height = 'auto';
        }
      }, 300);
    }
  };

  return (
    <nav style={{
      position: 'fixed',
      top: 0,
      left: 0,
      background: `url(${Background})`,
      backgroundPosition: 'center',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 24,
      boxSizing: 'border-box',
      overflow: 'auto',
      paddingBottom: 32,
    }}>
      <img style={{marginBottom: 45}} src={props.logo} alt={'Logo'} />
      <ul style={{alignSelf: 'stretch'}}>
        {props.links.map((item, index) => {
          const containerRef = React.createRef<HTMLLIElement>();
          const menuRef = React.createRef<HTMLDivElement>();
          switch (item.templateName) {
            case 'regularPopUpTemplate': {
              return (
                <LinkContainer ref={containerRef} key={index}>
                  <Link className={'menu'} onClick={() => toggleMenu(containerRef, menuRef)}>
                    {item.text}
                  </Link>
                  <MenuContainer ref={menuRef}>
                    <MenuSection>
                      {
                        props.links
                          .find(i => i.templateName === 'regularPopUpTemplate')
                          ?.links
                          ?.map((item, index) => (
                            <a href={item.link} key={index} style={{textDecoration: 'none'}}>
                              <ModuleItem color={'#f6f7fb'}>
                                <img style={{marginTop: 2, marginRight: 8}} src={item.logo} alt={item.altText}/>
                                <div>
                                  <div style={titleStyle}>{item.title}</div>
                                  <div style={descriptionStyle}>
                                    <ReactMarkdown>{item.description}</ReactMarkdown>
                                  </div>
                                </div>
                              </ModuleItem>
                            </a>
                          ))
                      }
                    </MenuSection>
                  </MenuContainer>
                </LinkContainer>
              );
            }
            case 'moduleListTemplate': {
              return (
                <LinkContainer ref={containerRef} key={index}>
                  <Link className={'menu'} onClick={() => toggleMenu(containerRef, menuRef)}>
                    {item.text}
                  </Link>
                  <MenuContainer ref={menuRef}>
                    <MenuSection style={{paddingBottom: 32}}>
                      {
                        props.modules
                          ?.map((module, index) => (
                            <a href={'/features#' + module.name} key={index} style={{textDecoration: 'none'}}>
                              <ModuleItem color={HSL.fromHex(module.color).setL(95).toHex()}>
                                <img style={{marginTop: 2, marginRight: 8, width: 32, height: 32}} src={module.icon} alt={module.altText}/>
                                <div>
                                  <div style={titleStyle}>{module.name}</div>
                                  <div style={descriptionStyle}>
                                    <ReactMarkdown>{module.description}</ReactMarkdown>
                                  </div>
                                </div>
                              </ModuleItem>
                            </a>
                          ))
                      }
                      <a
                        href={'/features'}
                        style={{
                          borderRadius: 8,
                          backgroundColor: '#4231a9',
                          textDecoration: 'none',
                          fontSize: 14,
                          fontWeight: 600,
                          color: 'white',
                          marginTop: 38,
                          height: 48,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <span>See all Features</span>
                        <i style={arrowStyle}/>
                      </a>
                    </MenuSection>
                    <MenuSection style={{background: '#f6f7fb'}}>
                      <span style={{
                        alignSelf: 'flex-start',
                        fontSize: 14,
                        marginBottom: 13,
                        marginTop: 24,
                        fontWeight: 600
                      }}>Use Cases:</span>
                      {
                        props.links
                          .find(i => i.templateName === 'moduleListTemplate')
                          ?.useCasesList
                          ?.sort((a, b) => a.position - b.position)
                          .slice(0, 4)
                          .map(({useCase}) => props.usecases.find(i => i.title === useCase))
                          .map((usecase, index) =>
                            usecase && <UseCaseButton key={index} href={usecase.link}>{usecase.title}</UseCaseButton>
                          )
                      }
                      <UseCaseButton style={{
                        background: 'none',
                        color: '#4231a9',
                        border: '1px solid #4231a9',
                        marginTop: 8,
                        marginBottom: 0
                      }} href={'/use-cases'}>See all</UseCaseButton>
                    </MenuSection>
                  </MenuContainer>
                </LinkContainer>
              );
            }
            default: {
              return (
                <LinkContainer key={index}>
                  <Link href={item.url}>{item.text}</Link>
                </LinkContainer>
              );
            }
          }
        })}
        <LinkContainer style={{marginBottom: 29}}>
          <Link
            style={{display: 'block', textDecoration: 'underline', marginTop: 25}}
            href="https://account.limeflight.com"
          >
            Sign In
          </Link>
        </LinkContainer>
        <LinkContainer style={{marginTop: 0, paddingTop: 0}}>
          <Button
            onClick={props.onFormOpen}
            color={'green'}
            buttonStyle={'default'}
            style={{marginLeft: 24}}
          >Contact Sales</Button>
        </LinkContainer>
      </ul>
    </nav>
  );
};
