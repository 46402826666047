import React from 'react';
import {Helmet} from 'react-helmet';

export const Favicon = (): JSX.Element => {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png"/>
      <link rel="manifest" href="/favicons/site.webmanifest"/>
      <link rel="shortcut icon" href="/favicons/favicon.ico"/>
      <link rel="icon" type="image/x-icon" href="/favicons/favicon.ico"/>
      <meta name="msapplication-TileColor" content="#2d89ef"/>
      <meta name="msapplication-config" content="/favicons/browserconfig.xml"/>
    </Helmet>
  );
};
