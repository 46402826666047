export class HSL {
  constructor(
    private h: number,
    private s: number,
    private l: number,
  ) {}

  public setH(value: number): HSL {
    return new HSL(value, this.s, this.l);
  }

  public setS(value: number): HSL {
    return new HSL(this.h, value, this.l);
  }

  public setL(value: number): HSL {
    return new HSL(this.h, this.s, value);
  }

  public toHex(): string {
    const h = this.h;
    const s = this.s / 100;
    const l = this.l / 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c/2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    let rHex = Math.round((r + m) * 255).toString(16);
    let gHex = Math.round((g + m) * 255).toString(16);
    let bHex = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (rHex.length === 1)
      rHex = '0' + r;
    if (gHex.length === 1)
      gHex = '0' + g;
    if (bHex.length === 1)
      bHex = '0' + b;

    return '#' + rHex + gHex + bHex;
  }

  static fromHex(hex: string): HSL {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt('0x' + hex[1] + hex[1], 16);
      g = parseInt('0x' + hex[2] + hex[2], 16);
      b = parseInt('0x' + hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt('0x' + hex[1] + hex[2], 16);
      g = parseInt('0x' + hex[3] + hex[4], 16);
      b = parseInt('0x' + hex[5] + hex[6], 16);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r,g,b);
    const cmax = Math.max(r,g,b);
    const delta = cmax - cmin;
    let h: number;
    let s: number;
    let l: number;

    if (delta === 0)
      h = 0;
    else if (cmax === r)
      h = ((g - b) / delta) % 6;
    else if (cmax === g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return new HSL(h, s, l);
  }
}
