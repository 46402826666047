import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {ModuleInfo} from '../../models/module-info';
import styled from 'styled-components';
import {device} from '../../models/devices';
import {TopBarPopupList} from './top-bar-popup-list';
import Arrows from '../../images/arrows-violet.svg';
import { Link } from 'gatsby';
import {HamburgerMenu} from './hamburger-menu';
import { MobileNavMenu } from './mobile-navigation';
import {UsecaseModel} from '../../models/usecase';
import {HSL} from '../../helpers/hsl';

const Logo = styled.img`
  margin-top: 5px;
  height: 39px;
  
  @media screen and ${device.tablet} {
    height: 27px;
    margin-top: 0;
    margin-left: 24px;
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  padding: 7px 13px;
  border-radius: 2px;
  
  .icon-chevron-down {
    font-size: 0.7em;
    margin-left: 7px;
  }
`;

const SeeAllButton = styled.a`
  padding: 14px 0;
  border-radius: 8px;
  background-color: #4231a9;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-top: 38px;
  cursor: pointer;

  &:hover {
    box-shadow: 10px 12px 14px 0 rgba(0, 0, 0, 0.07);
  }
`;

const Button = styled.button`
  padding: 16px 27px;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  color: #4f40ab;
  border: none;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  z-index: 30;
  transition: padding 0.3s linear;
  --bar-bg: white;
  
  @media screen and ${device.tablet} {
    &.mobileNavOpen {
      --bar-bg: white !important;
      padding: 30px 0 !important;
    }
  }
  
  &.light {
    ${Logo}.dark {
      display: none;
    }

    ${StyledLink}:hover {
      background-color: #0089FF;
    }

    .open ${StyledLink} {
      color: #0089ff;
    }
    
    .sticky & ${StyledLink}:hover,
    .open ${StyledLink}:hover {
      background: none;
    }
    
    .sticky & .open ${StyledLink} {
      color: #999999;
    }
    
    .sticky & ${StyledLink}:hover {
      color: #54595a;
    }
  }
  
  &.dark {
    --bar-bg: black;
    ${Logo}.light {
      display: none;
    }

    ${StyledLink} {
      color: #54595a;
      
      &:hover {
        color: black;
      }
    }

    ${Button} {
      background-color: #4f40ab;
      color: white;
    }
  }
  
  .sticky & {
    background: #fff;
    border-bottom: 1px solid #e4e4e4;
    padding: 13px 0;
    --bar-bg: black;
    &.light {
      ${Logo}.light {
        display: none;
      }
      ${Logo}.dark {
        display: block;
      }
    }
    ${StyledLink} { color: #999999; }
    ${Button} {
      background-color: #4f40ab;
      color: white;
    }
  }
`;

const Navigation = styled.nav`
  margin: 0 auto;
  
  @media screen and ${device.tablet} {
    display: none;
  }
`;

const navListStyle: CSSProperties = {
  display: 'flex',
  color: 'white',
  fontSize: 14,
  fontWeight: 500,
  height: 49,
  position: 'relative',
  zIndex: 20
};

const SignInContainer = styled.div`
  display: flex;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: initial;
  float: right;
  align-items: center;
  width: max-content;
  justify-content: flex-end;

  @media screen and ${device.tablet} {
    display: none;
  }
`;

const LinkContainer = styled.li`
  padding: 14px 3px;
  
  &.open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: white;
    padding-bottom: 28px;
    height: 20px;
  }
  
  &:not(:last-child) {
    margin-right: 21px;
  }
`;

const rightSidePanel: CSSProperties = {
  backgroundColor: '#f6f7fb',
  width: 268,
  flexGrow: 0,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '32px 16px 30px',
  boxSizing: 'border-box'
};

const UseCaseButton = styled.a`
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #157ee5;
  height: 45px;
  border-radius: 10px;
  box-shadow: 1px 1px 11px 0 rgba(216, 216, 216, 0.13);
  background-color: white;
  margin-bottom: 16px;
  line-height: 45px;

  &:hover {
    cursor: pointer;
    box-shadow: 10px 12px 14px 0 rgba(0, 0, 0, 0.04);
  }
`;

const arrowStyle: CSSProperties = {
  background: `url(${Arrows})`,
  display: 'inline-block',
  width: 16,
  height: 12,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  verticalAlign: 'middle',
  marginLeft: 8
};

const containerStyle: CSSProperties = {
  borderRadius: 10,
  boxShadow: '-10px 22px 44px 0 rgba(0, 0, 0, 0.13)',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'stretch',
  overflow: 'hidden'
};

export const TopBar = (props: {
  logoLight: string;
  logoDark: string;
  logoShort: string;
  links: Array<{
    text: string;
    templateName?: 'moduleListTemplate' | 'regularPopUpTemplate';
    url?: string;
    links?: Array<{
      logo: string;
      altText: string;
      link: string;
      title: string;
      description: string;
    }>;
    useCasesList?: Array<{
      useCase: string;
      position: number;
    }>;
  }>;
  usecases: Array<UsecaseModel>;
  onFormOpen?: () => void;
  modules: Array<ModuleInfo>;
  theme: 'light' | 'dark';
}): JSX.Element => {

  const isSSR = typeof window === 'undefined';
  const ref = useRef<HTMLDivElement>(null);

  const [openedPopup, setState] = useState<string | undefined>(undefined);
  const [mobileNavMenuIsOpen, setMobileMenuVisibility] = useState(false);

  const togglePopup = (templateName: string | undefined): void => {
    if (openedPopup === templateName) {
      setState('');
    } else {
      setState(templateName);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (!ref.current?.contains(event.target as Node)) {
      setState('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <Header className={`${props.theme}  ${mobileNavMenuIsOpen ? 'mobileNavOpen' : ''}`}>
      <div ref={ref} style={{position: 'relative', maxWidth: 1170, margin: '0 auto'}}>
        <Wrapper>
          <Link to="/" style={{width: 0}}>
            <Logo className={'light'} src={props.logoLight} alt="logo"/>
            <Logo className={'dark'} src={props.logoDark} alt="logo"/>
          </Link>
          <Navigation>
            <ul style={navListStyle}>
              {props.links.map((item, index) => {
                switch (item.templateName) {
                  case 'regularPopUpTemplate':
                  case 'moduleListTemplate': {
                    return (
                      <LinkContainer key={index} className={openedPopup === item.templateName ? 'open' : ''}>
                        <StyledLink onClick={() => togglePopup(item.templateName)}>
                          <span>{item.text}</span>
                          <i className="icon-chevron-down"/>
                        </StyledLink>
                      </LinkContainer>
                    );
                  }
                  default: {
                    return (
                      <LinkContainer key={index}>
                        <StyledLink href={item.url}>{item.text}</StyledLink>
                      </LinkContainer>
                    );
                  }
                }
              })}
            </ul>
          </Navigation>
          <div style={{width: 0}}>
            <SignInContainer>
              <StyledLink
                style={{marginRight: 32}}
                href="https://account.limeflight.com"
              >
                Sign In
              </StyledLink>
              <Button onClick={props.onFormOpen}>Contact Sales</Button>
            </SignInContainer>
          </div>
          {!isSSR && (
            <div className={'mobile-only'}>
              <div style={{position: 'absolute', top: 4, right: 24, zIndex: 3}}>
                <HamburgerMenu onClick={() => setMobileMenuVisibility(!mobileNavMenuIsOpen)}/>
              </div>
              {mobileNavMenuIsOpen && (
                <MobileNavMenu
                  links={props.links}
                  modules={props.modules}
                  logo={props.logoShort}
                  usecases={props.usecases}
                  onFormOpen={props.onFormOpen}
                />
              )}
            </div>
          )}
        </Wrapper>
        <div className={'desktop-only'} style={{
          position: 'absolute',
          top: 62,
          margin: '0 auto',
          display: 'none',
          left: 0,
          zIndex: 10,
          ...(openedPopup === 'moduleListTemplate') && {display: 'block'}
        }}>
          <div style={containerStyle}>
            <div style={{
              flexGrow: 1,
              padding: '54px 55px 30px',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <TopBarPopupList items={props.modules.map(module => ({
                link: '/features#' + module.name,
                title: module.name,
                icon: module.icon,
                text: module.description,
                altText: module.altText,
                color: HSL.fromHex(module.color).setL(95).toHex()
              }))}/>
              <SeeAllButton href={'/features'}>
                <span>See all Features</span>
                <i style={arrowStyle}/>
              </SeeAllButton>
            </div>
            <div style={rightSidePanel}>
              <span style={{alignSelf: 'flex-start', fontSize: 14, marginBottom: 14}}>Use Cases:</span>
              {
                props.links
                  .find(i => i.templateName === 'moduleListTemplate')
                  ?.useCasesList
                  ?.sort((a, b) => a.position - b.position)
                  .slice(0, 4)
                  .map(({useCase}) => props.usecases.find(i => i.title === useCase))
                  .map((usecase, index) =>
                    usecase && <UseCaseButton key={index} href={usecase.link}>{usecase.title}</UseCaseButton>
                  )
              }
              <UseCaseButton style={{
                background: 'none',
                color: '#4231a9',
                border: '1px solid #4231a9',
                marginTop: 'auto',
                marginBottom: 0
              }} href={'/use-cases'}>See all</UseCaseButton>
            </div>
          </div>
        </div>
        <div className={'desktop-only'} style={{
          position: 'absolute',
          top: 62,
          margin: '0 auto',
          display: 'none',
          left: '50%',
          transform: 'translate(-50%)',
          zIndex: 10,
          ...(openedPopup === 'regularPopUpTemplate') && {display: 'block'}
        }}>
          <div style={{
            ...containerStyle,
            padding: 54
          }}>
            <TopBarPopupList
              items={
                props.links
                  .find(i => i.templateName === 'regularPopUpTemplate')
                  ?.links
                  ?.map(i => ({
                    link: i.link,
                    title: i.title,
                    icon: i.logo,
                    text: i.description,
                    altText: i.altText,
                    color: '#f6f7fb'
                  })) ?? []
              }
            />
          </div>
        </div>
      </div>
    </Header>
  );
};
