import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import {ContactFormConfig} from '../../models/contact-form-config';

const gdprConsentStyle: CSSProperties = {
  margin: 0,
  marginTop: 29,
  marginBottom: 35,
  display: 'grid',
  gridTemplateRows: '0.5fr 1fr',
  gridGap: 15,
  fontSize: 12
};

export const GdprConsent = (props: {
  contactFormConfig: ContactFormConfig;
}): JSX.Element => {
  return (
    <section style={{...gdprConsentStyle, border: 'none', padding: 0, marginBottom: 39}}>

      {props.contactFormConfig.contactFormSettings.checkBoxList.map((checkBoxItem, index) => (
        <div key={index}>
          <label>
            <input name={checkBoxItem.hubSpotSubscriptionId} type="checkbox" value={checkBoxItem.hubSpotSubscriptionId}
                   defaultChecked={checkBoxItem.checkBoxDefaultChecked}/>
            {checkBoxItem.label}
          </label>
        </div>
      ))}

      <ReactMarkdown>{props.contactFormConfig.contactFormSettings.submissionText}</ReactMarkdown>
    </section>
  );
};

export default GdprConsent;
